import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Alert, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../services/api';
import ChallengeForm from './ChallengeForm';

const AdminDashboard = () => {
  const [challenges, setChallenges] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingChallenge, setEditingChallenge] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchChallenges();
  }, []);

  const fetchChallenges = async () => {
    try {
      const response = await api.get('/admin/challenges');
      setChallenges(response.data);
    } catch (error) {
      setError('Failed to fetch challenges');
    }
  };

  const handleCreateChallenge = async (challengeData) => {
    try {
      await api.post('/admin/challenges', challengeData);
      setSuccess('Challenge created successfully');
      setShowModal(false);
      fetchChallenges();
    } catch (error) {
      setError('Failed to create challenge');
    }
  };

  const handleUpdateChallenge = async (challengeData) => {
    try {
      await api.put(`/admin/challenges/${editingChallenge._id}`, challengeData);
      setSuccess('Challenge updated successfully');
      setShowModal(false);
      setEditingChallenge(null);
      fetchChallenges();
    } catch (error) {
      setError('Failed to update challenge');
    }
  };

  const handleDeleteChallenge = async (id) => {
    if (window.confirm('Are you sure you want to delete this challenge?')) {
      try {
        await api.delete(`/admin/challenges/${id}`);
        setSuccess('Challenge deleted successfully');
        fetchChallenges();
      } catch (error) {
        setError('Failed to delete challenge');
      }
    }
  };

  return (
    <Container>
      <h1 className="mb-4">Manage Challenges</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      
      <Button variant="primary" onClick={() => setShowModal(true)} className="mb-3">
        Create New Challenge
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Type</th>
            <th>Tasks</th>
            <th>Cost</th>
            <th>Attempts</th>
            <th>Attempt Timeout</th>
            <th>Created At</th>
            <th>Last Updated</th>
            <th>Tweet Winner</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {challenges.map((challenge) => (
            <tr key={challenge._id}>
              <td>{challenge.title}</td>
              <td>{new Date(challenge.startDate).toLocaleString()}</td>
              <td>{new Date(challenge.endDate).toLocaleString()}</td>
              <td>{challenge.type}</td>
              <td>{challenge.tasks.length}</td>
              <td>{challenge.cost}</td>
              <td>{challenge.attempts}</td>
              <td>{challenge.attemptTimeout} minutes</td>
              <td>{new Date(challenge.createdAt).toLocaleString()}</td>
              <td>{new Date(challenge.updatedAt).toLocaleString()}</td>
              <td>{challenge.tweetWinner ? 'Yes' : 'No'}</td>
              <td>
                <Button variant="info" onClick={() => { setEditingChallenge(challenge); setShowModal(true); }} className="me-2">
                  Edit
                </Button>
                <Button variant="danger" onClick={() => handleDeleteChallenge(challenge._id)} className="me-2">
                  Delete
                </Button>
                <Link to={`/admin/challenges/${challenge._id}/submissions`} className="btn btn-secondary">
                  View Submissions
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => { setShowModal(false); setEditingChallenge(null); }} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{editingChallenge ? 'Edit Challenge' : 'Create New Challenge'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChallengeForm
            challenge={editingChallenge}
            onSubmit={editingChallenge ? handleUpdateChallenge : handleCreateChallenge}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AdminDashboard;