import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Alert, Modal, Tabs, Tab } from 'react-bootstrap';
import api from '../services/api';
import SubscriptionForm from './SubscriptionForm';
import StoreItemForm from './StoreItemForm';

const AdminStore = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [storeItems, setStoreItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [activeTab, setActiveTab] = useState('subscriptions');

  useEffect(() => {
    fetchSubscriptions();
    fetchStoreItems();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await api.get('/admin/subscriptions');
      setSubscriptions(response.data);
    } catch (error) {
      setError('Failed to fetch subscriptions');
    }
  };

  const fetchStoreItems = async () => {
    try {
      const response = await api.get('/admin/store-items');
      setStoreItems(response.data);
    } catch (error) {
      setError('Failed to fetch store items');
    }
  };

  const handleCreateSubscription = async (subscriptionData) => {
    try {
      await api.post('/admin/subscriptions', subscriptionData);
      setSuccess('Subscription created successfully');
      setShowModal(false);
      fetchSubscriptions();
    } catch (error) {
      setError('Failed to create subscription');
    }
  };

  const handleUpdateSubscription = async (subscriptionData) => {
    try {
      await api.put(`/admin/subscriptions/${editingItem._id}`, subscriptionData);
      setSuccess('Subscription updated successfully');
      setShowModal(false);
      setEditingItem(null);
      fetchSubscriptions();
    } catch (error) {
      setError('Failed to update subscription');
    }
  };

  const handleDeleteSubscription = async (id) => {
    if (window.confirm('Are you sure you want to delete this subscription?')) {
      try {
        await api.delete(`/admin/subscriptions/${id}`);
        setSuccess('Subscription deleted successfully');
        fetchSubscriptions();
      } catch (error) {
        setError('Failed to delete subscription');
      }
    }
  };

  const handleCreateStoreItem = async (storeItemData) => {
    try {
      await api.post('/admin/store-items', storeItemData);
      setSuccess('Store item created successfully');
      setShowModal(false);
      fetchStoreItems();
    } catch (error) {
      setError('Failed to create store item');
    }
  };

  const handleUpdateStoreItem = async (storeItemData) => {
    try {
      await api.put(`/admin/store-items/${editingItem._id}`, storeItemData);
      setSuccess('Store item updated successfully');
      setShowModal(false);
      setEditingItem(null);
      fetchStoreItems();
    } catch (error) {
      setError('Failed to update store item');
    }
  };

  const handleDeleteStoreItem = async (id) => {
    if (window.confirm('Are you sure you want to delete this store item?')) {
      try {
        await api.delete(`/admin/store-items/${id}`);
        setSuccess('Store item deleted successfully');
        fetchStoreItems();
      } catch (error) {
        setError('Failed to delete store item');
      }
    }
  };

  return (
    <Container>
      <h1 className="mb-4">Manage Store</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      
      <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
        <Tab eventKey="subscriptions" title="Subscriptions">
          <Button variant="primary" onClick={() => { setEditingItem(null); setShowModal(true); }} className="mb-3">
            Create New Subscription
          </Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Tier</th>
                <th>Token Bonus</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((subscription) => (
                <tr key={subscription._id}>
                  <td>{subscription.name}</td>
                  <td>{subscription.tier}</td>
                  <td>{subscription.tokenBonus}</td>
                  <td>
                    <Button variant="info" onClick={() => { setEditingItem(subscription); setShowModal(true); }} className="me-2">
                      Edit
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteSubscription(subscription._id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="storeItems" title="Store Items">
          <Button variant="primary" onClick={() => { setEditingItem(null); setShowModal(true); }} className="mb-3">
            Create New Store Item
          </Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Quantity</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {storeItems.map((item) => (
                <tr key={item._id}>
                  <td>{item.name}</td>
                  <td>{item.type}</td>
                  <td>{item.quantity}</td>
                  <td>
                    <Button variant="info" onClick={() => { setEditingItem(item); setShowModal(true); }} className="me-2">
                      Edit
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteStoreItem(item._id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>

      <Modal show={showModal} onHide={() => { setShowModal(false); setEditingItem(null); }} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {editingItem ? 'Edit' : 'Create'} {activeTab === 'subscriptions' ? 'Subscription' : 'Store Item'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeTab === 'subscriptions' ? (
            <SubscriptionForm
              subscription={editingItem}
              onSubmit={editingItem ? handleUpdateSubscription : handleCreateSubscription}
            />
          ) : (
            <StoreItemForm
              storeItem={editingItem}
              onSubmit={editingItem ? handleUpdateStoreItem : handleCreateStoreItem}
            />
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AdminStore;