import React, { useState } from 'react';
import { Container, Form, Button, Alert, Card } from 'react-bootstrap';
import api from '../services/api';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  max-width: 600px;
  margin-top: 2rem;
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StyledCardHeader = styled(Card.Header)`
  background-color: #1DA1F2;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
`;

const TweetInfo = styled.p`
  font-style: italic;
  color: #555;
  margin-bottom: 1rem;
`;

const CharCount = styled.span`
  float: right;
  color: ${props => props.count > 260 ? 'red' : 'inherit'};
`;

const AdminTweet = () => {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/admin/tweet', { message });
      setSuccess('Tweet sent successfully!');
      setMessage('');
      setError('');
    } catch (error) {
      setError('Failed to send tweet. Please try again.');
      setSuccess('');
    }
  };

  return (
    <StyledContainer>
      <StyledCard>
        <StyledCardHeader>Send Tweet as @officialcuzzl</StyledCardHeader>
        <Card.Body>
          <TweetInfo>
            This tweet will be sent from the official Cuzzl Twitter account (@officialcuzzl).
          </TweetInfo>
          {success && <Alert variant="success">{success}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                rows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                maxLength={280}
                placeholder="What's happening?"
                required
              />
              <CharCount count={message.length}>{message.length}/280</CharCount>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={message.length > 280}>
              Tweet
            </Button>
          </Form>
        </Card.Body>
      </StyledCard>
    </StyledContainer>
  );
};

export default AdminTweet;