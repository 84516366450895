import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';

const Login = () => {
  const handleTwitterLogin = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log('Initiating Twitter login. API URL:', apiUrl);
    window.location.href = `${apiUrl}/auth/twitter?platform=web&force_login=true`;
  };

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <Row className="w-100">
        <Col md={6} className="mx-auto">
          <Card className="shadow-lg">
            <Card.Body className="p-5">
              <h2 className="text-center mb-4">Login</h2>
              <Button variant="info" onClick={handleTwitterLogin} className="w-100 mt-3">
                Login with Twitter
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;