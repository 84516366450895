import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Table, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faTrophy, faCheckCircle, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import api from '../services/api';

const AdminDashboard = () => {
  const [stats, setStats] = useState(null);
  const [recentChallenges, setRecentChallenges] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const [statsResponse, challengesResponse] = await Promise.all([
        api.get('/admin/dashboard/stats'),
        api.get('/admin/dashboard/recent-challenges')
      ]);
      setStats(statsResponse.data);
      setRecentChallenges(challengesResponse.data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <h1 className="mb-4">Admin Dashboard</h1>
      <Row>
        <Col md={3}>
          <StatCard
            title="Recently Active Players"
            value={stats.recentlyActivePlayers}
            icon={faUsers}
            color="#007bff"
          />
        </Col>
        <Col md={3}>
          <StatCard
            title="Active Challenges"
            value={stats.activeChallenges}
            icon={faTrophy}
            color="#28a745"
          />
        </Col>
        <Col md={3}>
          <StatCard
            title="Completed Challenges"
            value={stats.completedChallenges}
            icon={faCheckCircle}
            color="#ffc107"
          />
        </Col>
        <Col md={3}>
          <StatCard
            title="Upcoming Challenges"
            value={stats.upcomingChallenges}
            icon={faCalendarAlt}
            color="#17a2b8"
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>Recent Challenges</Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Start Date</th>
                    <th>Participants</th>
                  </tr>
                </thead>
                <tbody>
                  {recentChallenges.map((challenge) => (
                    <tr key={challenge._id}>
                      <td>{challenge.title}</td>
                      <td>{new Date(challenge.startDate).toLocaleDateString()}</td>
                      <td>{challenge.participantsCount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const StatCard = ({ title, value, icon, color }) => (
  <Card className="mb-3">
    <Card.Body>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <Card.Title>{title}</Card.Title>
          <Card.Text as="h2">{value}</Card.Text>
        </div>
        <FontAwesomeIcon icon={icon} size="3x" style={{ color }} />
      </div>
    </Card.Body>
  </Card>
);

export default AdminDashboard;