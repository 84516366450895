import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Export these functions directly
export const verifyEmail = (token) => api.get(`/auth/verify/${token}`);
export const getCurrentUser = () => api.get('/auth/me');
export const getDailyChallenges = () => api.get('/admin/challenges');
export const createChallenge = (challenge) => api.post('/admin/challenges', challenge);
export const deleteChallenge = (id) => api.delete(`/admin/challenges/${id}`);
export const getUserSubmissions = (challengeId) => api.get(`/admin/challenges/${challengeId}/submissions`);
export const deleteSubmission = (challengeId, userId) => api.delete(`/admin/challenges/${challengeId}/submissions/${userId}`);

export const getRecentChallenges = () => api.get('/game/recent-challenges');
export const getLeaderboard = (challengeId) => api.get(`/game/leaderboard/${challengeId}`);

// Export the api object as default
export default api;