import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import Game from './components/Game';
import VerifyEmail from './components/VerifyEmail';
import AdminDashboard from './components/AdminDashboard';
import AdminChallenges from './components/AdminChallenges';
import AdminSubmissions from './components/AdminSubmissions';
import AdminStore from './components/AdminStore';  // Add this import
import AppNavbar from './components/Navbar';
import PublicRoute from './components/PublicRoute';
import AuthCallback from './components/AuthCallback';
import PrivacyPolicy from './components/PrivacyPolicy';
import Leaderboard from './components/Leaderboard';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import styled from 'styled-components';
import GlobalStyles from './GlobalStyles';
import AdminTweet from './components/AdminTweet';

const AppWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  padding-bottom: 60px; // Adjust this value based on your footer's height
`;

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return null; // or a loading spinner
  return user ? children : <Navigate to="/login" />;
};

const AdminRoute = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return null; // or a loading spinner
  return user && user.role === 'admin' ? children : <Navigate to="/" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <GlobalStyles />
        <AppWrapper>
          <ContentWrapper>
            <AppNavbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/login"
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route path="/verify/:token" element={<VerifyEmail />} />
              <Route path="/auth" element={<AuthCallback />} />
              <Route
                path="/game"
                element={
                    <Game />
                }
              />
              <Route
                path="/admin"
                element={
                  <AdminRoute>
                    <AdminDashboard />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/challenges"
                element={
                  <AdminRoute>
                    <AdminChallenges />
                  </AdminRoute>
                }
              />
              <Route path="/admin/challenges/:challengeId/submissions" element={<AdminSubmissions />} />
              <Route
                path="/admin/submissions"
                element={
                  <AdminRoute>
                    <AdminSubmissions />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/store"
                element={
                  <AdminRoute>
                    <AdminStore />
                  </AdminRoute>
                }
              />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route
                path="/leaderboard"
                element={
                    <Leaderboard />
                }
              />
              <Route
                path="/admin/tweet"
                element={
                  <AdminRoute>
                    <AdminTweet />
                  </AdminRoute>
                }
              />
            </Routes>
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </Router>
    </AuthProvider>
  );
}

export default App;