import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

const SubscriptionForm = ({ subscription, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    features: '',
    productId: '',
    isActive: true,
    tier: 'silver',
    tokenBonus: 0,
  });

  useEffect(() => {
    if (subscription) {
      setFormData({
        ...subscription,
        features: subscription.features.join(', '),
      });
    }
  }, [subscription]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      features: formData.features.split(',').map(feature => feature.trim()),
      tokenBonus: parseInt(formData.tokenBonus),
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control as="textarea" name="description" value={formData.description} onChange={handleChange} required />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Features (comma-separated)</Form.Label>
        <Form.Control type="text" name="features" value={formData.features} onChange={handleChange} required />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Product ID</Form.Label>
        <Form.Control type="text" name="productId" value={formData.productId} onChange={handleChange} required />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check type="checkbox" label="Is Active" name="isActive" checked={formData.isActive} onChange={handleChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Tier</Form.Label>
        <Form.Control as="select" name="tier" value={formData.tier} onChange={handleChange} required>
          <option value="silver">Silver</option>
          <option value="gold">Gold</option>
          <option value="platinum">Platinum</option>
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Token Bonus</Form.Label>
        <Form.Control type="number" name="tokenBonus" value={formData.tokenBonus} onChange={handleChange} required />
      </Form.Group>
      <Button variant="primary" type="submit">
        {subscription ? 'Update' : 'Create'} Subscription
      </Button>
    </Form>
  );
};

export default SubscriptionForm;