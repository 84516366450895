import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AppNavbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handlePlay = () => {
    navigate('/game');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">Cuzzl</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {user && user.role === 'admin' && (
              <>
                <Nav.Link as={Link} to="/admin">Admin Dashboard</Nav.Link>
                <Nav.Link as={Link} to="/admin/challenges">Manage Challenges</Nav.Link>
                <Nav.Link as={Link} to="/admin/submissions">View Submissions</Nav.Link>
                <Nav.Link as={Link} to="/admin/store">Manage Store</Nav.Link>
                <Nav.Link as={Link} to="/admin/tweet">Send Tweet</Nav.Link>
              </>
            )}
          </Nav>
          <Nav>
            <Button variant="outline-light" onClick={handlePlay} className="me-2">Play</Button>
            <Nav.Link as={Link} to="/leaderboard">Leaderboard</Nav.Link>
            {user ? (
              <>
                <Nav.Link as={Link} to="/profile">Profile</Nav.Link>
                <Nav.Link onClick={logout}>Logout</Nav.Link>
              </>
            ) : (
              <Nav.Link as={Link} to="/login">Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;