import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

const StoreItemForm = ({ storeItem, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    type: 'token',
    quantity: '',
    productId: '',
    isActive: true,
  });

  useEffect(() => {
    if (storeItem) {
      setFormData(storeItem);
    }
  }, [storeItem]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      quantity: parseInt(formData.quantity),
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control as="textarea" name="description" value={formData.description} onChange={handleChange} required />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Type</Form.Label>
        <Form.Control as="select" name="type" value={formData.type} onChange={handleChange} required>
          <option value="token">Token</option>
          <option value="powerup">Power-up</option>
          <option value="cosmetic">Cosmetic</option>
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Quantity</Form.Label>
        <Form.Control type="number" name="quantity" value={formData.quantity} onChange={handleChange} required />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Product ID</Form.Label>
        <Form.Control type="text" name="productId" value={formData.productId} onChange={handleChange} required />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check type="checkbox" label="Is Active" name="isActive" checked={formData.isActive} onChange={handleChange} />
      </Form.Group>
      <Button variant="primary" type="submit">
        {storeItem ? 'Update' : 'Create'} Store Item
      </Button>
    </Form>
  );
};

export default StoreItemForm;