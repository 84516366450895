import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Alert, Spinner } from 'react-bootstrap';
import api from '../services/api';

const VerifyEmail = () => {
  const [status, setStatus] = useState('Verifying...');
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await api.get(`/auth/verify/${token}`);
        setStatus('Your email has been successfully verified. Redirecting to login...');
        setTimeout(() => {
          navigate('/login');
        }, 3000); // Redirect after 3 seconds
      } catch (error) {
        console.error('Verification failed:', error);
        setStatus('Email verification failed. Please try again or contact support.');
      }
    };

    verifyEmail();
  }, [token, navigate]);

  return (
    <Container className="mt-5 text-center">
      {status === 'Verifying...' ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Alert variant={status.includes('successfully') ? 'success' : 'danger'}>
          {status}
        </Alert>
      )}
    </Container>
  );
};

export default VerifyEmail;