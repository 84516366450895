import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');

      if (token) {
        await login(token);
        navigate('/game');
      } else {
        navigate('/login');
      }
    };

    handleAuthCallback();
  }, [location, login, navigate]);

  return <div>Authenticating...</div>;
};

export default AuthCallback;