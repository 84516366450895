import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PublicRoute = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return null; // or a loading spinner
  return user ? <Navigate to="/game" /> : children;
};

export default PublicRoute;