import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: #f8f9fa;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const FooterText = styled.p`
  margin-bottom: 0;
`;

const FooterLink = styled(Link)`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <Container>
        <Row className="align-items-center">
          <Col xs={4} className="text-start">
            <FooterLink to="/privacy">Privacy Policy</FooterLink>
          </Col>
          <Col xs={4} className="text-center">
            <FooterText>© {currentYear} Cuzzl. All rights reserved.</FooterText>
          </Col>
          <Col xs={4} className="text-end">
            <FooterLink to="/game">Play</FooterLink>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;