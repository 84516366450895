import React from 'react';
import { Container, Alert, Button } from 'react-bootstrap';

const Game = () => {
  const handleDownload = () => {
    // Replace with your actual App Store URL
    window.location.href = 'https://apps.apple.com/us/app/cuzzl';
  };

  return (
    <Container>
      <h1 className="mb-4">Cuzzl</h1>
      <Alert variant="info">
        Welcome to Cuzzl! The game is currently not available in the web version. 
        Please download our mobile app for the full gaming experience.
      </Alert>
      <Button variant="primary" size="lg" onClick={handleDownload}>
        Download from App Store
      </Button>
    </Container>
  );
};

export default Game;