import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

const ChallengeForm = ({ challenge, onSubmit }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    type: 'daily',
    tasks: [],
    cost: 0,
    attempts: 1,
    attemptTimeout: 0,
    score: 100, // Add this line to include the default score
    tweetWinner: false,
  });

  useEffect(() => {
    if (challenge) {
      const startDateTime = new Date(challenge.startDate);
      const endDateTime = new Date(challenge.endDate);
      setFormData({
        ...challenge,
        startDate: startDateTime.toISOString().split('T')[0],
        startTime: startDateTime.toTimeString().slice(0, 5),
        endDate: endDateTime.toISOString().split('T')[0],
        endTime: endDateTime.toTimeString().slice(0, 5)
      });
    }
  }, [challenge]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleTaskChange = (index, e) => {
    const updatedTasks = formData.tasks.map((task, i) => {
      if (i === index) {
        if (e.target.name === 'type') {
          return { 
            ...task, 
            [e.target.name]: e.target.value, 
            answers: e.target.value === 'text' ? [{ text: '', isCorrect: true }] : 
                     e.target.value === 'multiple_choice' ? [{ text: '', isCorrect: false }] : [],
            correctAnswer: e.target.value === 'text' ? '' : undefined
          };
        }
        return { ...task, [e.target.name]: e.target.value };
      }
      return task;
    });
    setFormData({ ...formData, tasks: updatedTasks });
  };

  const handleAnswerChange = (taskIndex, answerIndex, e) => {
    const updatedTasks = formData.tasks.map((task, i) => {
      if (i === taskIndex) {
        if (task.type === 'text') {
          return {
            ...task,
            correctAnswer: e.target.value,
            answers: [{ text: e.target.value, isCorrect: true }]
          };
        } else {
          const updatedAnswers = task.answers.map((answer, j) => {
            if (j === answerIndex) {
              return { ...answer, [e.target.name]: e.target.name === 'isCorrect' ? e.target.checked : e.target.value };
            }
            return answer;
          });
          return { ...task, answers: updatedAnswers };
        }
      }
      return task;
    });
    setFormData({ ...formData, tasks: updatedTasks });
  };

  const addTask = () => {
    setFormData({
      ...formData,
      tasks: [...formData.tasks, { order: formData.tasks.length + 1, description: '', type: 'photo', answers: [] }]
    });
  };

  const removeTask = (index) => {
    setFormData(prevData => ({
      ...prevData,
      tasks: prevData.tasks.filter((_, i) => i !== index).map((task, i) => ({ ...task, order: i + 1 }))
    }));
  };

  const addAnswer = (taskIndex) => {
    const updatedTasks = formData.tasks.map((task, i) => {
      if (i === taskIndex) {
        return { ...task, answers: [...task.answers, { text: '', isCorrect: false }] };
      }
      return task;
    });
    setFormData({ ...formData, tasks: updatedTasks });
  };

  const removeAnswer = (taskIndex, answerIndex) => {
    const updatedTasks = formData.tasks.map((task, i) => {
      if (i === taskIndex) {
        return { ...task, answers: task.answers.filter((_, j) => j !== answerIndex) };
      }
      return task;
    });
    setFormData({ ...formData, tasks: updatedTasks });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      startDate: new Date(`${formData.startDate}T${formData.startTime}`).toISOString(),
      endDate: new Date(`${formData.endDate}T${formData.endTime}`).toISOString(),
      cost: Number(formData.cost),
      attempts: Number(formData.attempts),
      attemptTimeout: Number(formData.attemptTimeout),
      score: Number(formData.score),
      tweetWinner: formData.tweetWinner,
      tasks: formData.tasks.map(task => ({
        ...task,
        answers: task.type === 'text' 
          ? [{ text: task.correctAnswer, isCorrect: true }] 
          : task.answers
      }))
    };
    onSubmit(submissionData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>End Time</Form.Label>
            <Form.Control
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group>
        <Form.Label>Type</Form.Label>
        <Form.Control
          as="select"
          name="type"
          value={formData.type}
          onChange={handleChange}
          required
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Cost (Tokens)</Form.Label>
        <Form.Control
          type="number"
          name="cost"
          value={formData.cost}
          onChange={handleChange}
          required
          min="0"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Attempts</Form.Label>
        <Form.Control
          type="number"
          name="attempts"
          value={formData.attempts}
          onChange={handleChange}
          required
          min="1"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Attempt Timeout (minutes)</Form.Label>
        <Form.Control
          type="number"
          name="attemptTimeout"
          value={formData.attemptTimeout}
          onChange={handleChange}
          required
          min="0"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Score</Form.Label>
        <Form.Control
          type="number"
          name="score"
          value={formData.score}
          onChange={handleChange}
          required
          min="0"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Tweet Winner"
          name="tweetWinner"
          checked={formData.tweetWinner}
          onChange={handleChange}
        />
      </Form.Group>

      <h4>Tasks</h4>
      {formData.tasks.map((task, taskIndex) => (
        <div key={taskIndex} className="mb-3 p-3 border rounded">
          <h5>Task {taskIndex + 1}</h5>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={task.description}
                  onChange={(e) => handleTaskChange(taskIndex, e)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={task.type}
                  onChange={(e) => handleTaskChange(taskIndex, e)}
                  required
                >
                  <option value="photo">Photo</option>
                  <option value="video">Video</option>
                  <option value="text">Text</option>
                  <option value="multiple_choice">Multiple Choice</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={2} className="d-flex align-items-end">
              <Button variant="danger" onClick={() => removeTask(taskIndex)} className="mb-3">Remove Task</Button>
            </Col>
          </Row>
          {task.type === 'text' && (
            <Form.Group>
              <Form.Label>Correct Answer</Form.Label>
              <Form.Control
                type="text"
                value={task.correctAnswer || ''}
                onChange={(e) => handleAnswerChange(taskIndex, 0, e)}
                required
              />
            </Form.Group>
          )}
          {task.type === 'multiple_choice' && (
            <div>
              <h6>Answers</h6>
              {task.answers.map((answer, answerIndex) => (
                <Row key={answerIndex} className="mb-2">
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      name="text"
                      value={answer.text}
                      onChange={(e) => handleAnswerChange(taskIndex, answerIndex, e)}
                      placeholder="Answer text"
                      required
                    />
                  </Col>
                  <Col md={2}>
                    <Form.Check
                      type="checkbox"
                      name="isCorrect"
                      checked={answer.isCorrect}
                      onChange={(e) => handleAnswerChange(taskIndex, answerIndex, e)}
                      label="Correct"
                    />
                  </Col>
                  <Col md={2}>
                    <Button variant="danger" onClick={() => removeAnswer(taskIndex, answerIndex)}>Remove</Button>
                  </Col>
                </Row>
              ))}
              <Button variant="secondary" onClick={() => addAnswer(taskIndex)} className="mt-2">Add Answer</Button>
            </div>
          )}
        </div>
      ))}
      <Button variant="secondary" onClick={addTask} className="mb-3">Add Task</Button>
      <br />
      <Button variant="primary" type="submit">
        {challenge ? 'Update Challenge' : 'Create Challenge'}
      </Button>
    </Form>
  );
};

export default ChallengeForm;