import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container className="mt-4">
      <h1>Privacy Policy</h1>
      <p>Last updated: 09/06/2024</p>
      
      <h2>1. Introduction</h2>
      <p>Welcome to Cuzzl ("we," "our," or "us"), an application operated by MastrKey LLC. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application and website (collectively, the "Service").</p>
      
      <h2>2. Information Collection</h2>
      <p>We may collect information about you in various ways when you use our Service. The information we may collect includes:</p>
      <ul>
        <li>Personal information you provide to us</li>
        <li>Information automatically collected when using our Service</li>
        <li>Information from third-party sources</li>
      </ul>

      <h2>3. Use of Information</h2>
      <p>We use the information we collect for various purposes, including to:</p>
      <ul>
        <li>Provide, maintain, and improve our Service</li>
        <li>Communicate with you</li>
        <li>Monitor and analyze usage patterns and trends</li>
      </ul>

      <h2>4. Sharing of Information</h2>
      <p>We may share information we collect as follows:</p>
      <ul>
        <li>With service providers</li>
        <li>For legal reasons</li>
        <li>In connection with a business transaction</li>
      </ul>

      <h2>5. Data Security</h2>
      <p>We implement reasonable security measures to protect your information. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

      <h2>6. Your Choices</h2>
      <p>You may have certain rights regarding your personal information, depending on your location.</p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h2>8. Disclaimer of Liability</h2>
      <p>MastrKey LLC and Cuzzl are not liable for any damages or losses arising from your use of the Service or any information contained within. Use of the Service is at your own risk.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at: <b>support@cuzzl.com</b></p>
    </Container>
  );
};

export default PrivacyPolicy;