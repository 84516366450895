import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Alert, Form, Pagination, Modal } from 'react-bootstrap';
import api from '../services/api';
import UserSubmissionsModal from './UserSubmissionsModal';

const AdminSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [submissionsPerPage] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submissionToDelete, setSubmissionToDelete] = useState(null);

  useEffect(() => {
    fetchChallenges();
  }, []);

  useEffect(() => {
    if (selectedChallenge) {
      fetchSubmissions(selectedChallenge);
    }
  }, [selectedChallenge]);

  const fetchChallenges = async () => {
    try {
      const response = await api.get('/admin/challenges');
      setChallenges(response.data);
      if (response.data.length > 0) {
        setSelectedChallenge(response.data[0]._id);
      }
    } catch (error) {
      setError('Failed to fetch challenges. Please try again.');
    }
  };

  const fetchSubmissions = async (challengeId) => {
    try {
      setLoading(true);
      const response = await api.get(`/admin/challenges/${challengeId}/submissions`);
      setSubmissions(response.data);
    } catch (error) {
      setError('Failed to fetch submissions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    fetchSubmissions(selectedChallenge);
  };

  const handleDeleteClick = (submission) => {
    setSubmissionToDelete(submission);
    setShowDeleteModal(true);
  };

  const handleDeleteSubmission = async () => {
    try {
      await api.delete(`/admin/challenges/${selectedChallenge}/submissions/${submissionToDelete.userId}`);
      setSuccess('Submission deleted successfully');
      fetchSubmissions(selectedChallenge);
    } catch (error) {
      setError('Failed to delete submission. Please try again.');
    } finally {
      setShowDeleteModal(false);
      setSubmissionToDelete(null);
    }
  };

  // Pagination
  const indexOfLastSubmission = currentPage * submissionsPerPage;
  const indexOfFirstSubmission = indexOfLastSubmission - submissionsPerPage;
  const currentSubmissions = submissions.slice(indexOfFirstSubmission, indexOfLastSubmission);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="mt-4">
      <h1 className="mb-4">User Submissions</h1>
      
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      
      <Form.Group className="mb-3">
        <Form.Label>Select Challenge</Form.Label>
        <Form.Control 
          as="select" 
          value={selectedChallenge} 
          onChange={(e) => setSelectedChallenge(e.target.value)}
        >
          {challenges.map((challenge) => (
            <option key={challenge._id} value={challenge._id}>
              {new Date(challenge.startDate).toLocaleDateString()} - {challenge.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {submissions.length > 0 ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Username</th>
                <th>Total Attempts</th>
                <th>Latest Attempt Completed</th>
                <th>Latest Attempt Correct</th>
                <th>Needs Verification</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentSubmissions.map((submission) => (
                <tr key={submission.userId}>
                  <td>{submission.username}</td>
                  <td>{submission.totalAttempts}</td>
                  <td>{submission.isCompleted ? 'Yes' : 'No'}</td>
                  <td>{submission.isCorrect ? 'Yes' : 'No'}</td>
                  <td>{submission.needsVerification ? 'Yes' : 'No'}</td>
                  <td>
                    <Button 
                      variant="primary" 
                      onClick={() => handleUserClick(submission)}
                      className="me-2"
                    >
                      View Attempts
                    </Button>
                    <Button 
                      variant="danger" 
                      onClick={() => handleDeleteClick(submission)}
                    >
                      Delete All
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            {[...Array(Math.ceil(submissions.length / submissionsPerPage)).keys()].map(number => (
              <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                {number + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </>
      ) : (
        <Alert variant="info">No submissions found for this challenge.</Alert>
      )}

      <UserSubmissionsModal
        show={showModal}
        onHide={handleModalClose}
        user={selectedUser}
        challengeId={selectedChallenge}
      />

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete all submissions for {submissionToDelete?.username}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSubmission}>
            Delete All
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AdminSubmissions;