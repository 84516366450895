import React, { useState, useEffect } from 'react';
import { Container, Form, Table, Spinner, Alert } from 'react-bootstrap';
import { getRecentChallenges, getLeaderboard } from '../services/api';

const Leaderboard = () => {
  const [challenges, setChallenges] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState('all-time');
  const [leaderboardEntries, setLeaderboardEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchChallenges();
  }, []);

  useEffect(() => {
    if (selectedChallenge) {
      fetchLeaderboard(selectedChallenge);
    }
  }, [selectedChallenge]);

  const fetchChallenges = async () => {
    try {
      const response = await getRecentChallenges();
      console.log('Recent challenges:', response.data);
      const formattedChallenges = response.data.map(challenge => ({
        id: challenge._id,
        title: `${formatDate(challenge.startDate)}: ${challenge.title}`
      }));
      setChallenges([{ id: 'all-time', title: 'All Time' }, ...formattedChallenges]);
    } catch (error) {
      console.error('Error fetching challenges:', error);
      setError('Failed to fetch challenges. Please try again later.');
    }
  };

  const fetchLeaderboard = async (challengeId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getLeaderboard(challengeId);
      console.log('Leaderboard response:', response.data);
      const entries = challengeId === 'all-time' ? response.data : response.data.entries;
      setLeaderboardEntries(entries || []);
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      setError('Failed to fetch leaderboard data. Please try again later.');
      setLeaderboardEntries([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChallengeChange = (event) => {
    setSelectedChallenge(event.target.value);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const formatTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.getMinutes()}:${date.getSeconds().toString().padStart(2, '0')}`;
  };

  return (
    <Container>
      <h1 className="mb-4">Leaderboard</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group className="mb-3">
        <Form.Label>Select Challenge</Form.Label>
        <Form.Control as="select" value={selectedChallenge} onChange={handleChallengeChange}>
          {challenges.map((challenge) => (
            <option key={challenge.id} value={challenge.id}>
              {challenge.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : leaderboardEntries.length === 0 ? (
        <p>No leaderboard data available.</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Username</th>
              {selectedChallenge === 'all-time' ? (
                <th>Wins</th>
              ) : (
                <th>Completion Time</th>
              )}
            </tr>
          </thead>
          <tbody>
            {leaderboardEntries.map((entry, index) => (
              <tr key={entry._id || entry.id || index}>
                <td>{entry.rank || index + 1}</td>
                <td>{entry.username}</td>
                {selectedChallenge === 'all-time' ? (
                  <td>{entry.correctCompletions}</td>
                ) : (
                  <td>{formatTime(entry.completionTime)}</td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default Leaderboard;