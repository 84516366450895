import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HomePageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 4rem;
  margin-bottom: 2rem;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const HomePage = () => {
  return (
    <HomePageWrapper>
      <Container>
        <Title>Welcome to Cuzzl</Title>
        <Subtitle>Challenge yourself with daily puzzles and compete with friends!</Subtitle>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button as={Link} to="/game" variant="light" size="lg" className="me-2">
              Play Now
            </Button>
          </Col>
          <Col xs="auto">
            <Button as={Link} to="/leaderboard" variant="outline-light" size="lg">
              Leaderboards
            </Button>
          </Col>
        </Row>
      </Container>
    </HomePageWrapper>
  );
};

export default HomePage;