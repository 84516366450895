import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Form, Alert, Spinner, Row, Col, Accordion } from 'react-bootstrap';
import api from '../services/api';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 90%;
    margin: 1.75rem auto;
  }
`;

const MediaContainer = styled.div`
  margin-bottom: 1rem;
  img, video {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
  }
`;

const TaskContainer = styled(Card)`
  margin-bottom: 1rem;
  height: 100%;
`;

const UserSubmissionsModal = ({ show, onHide, user, challengeId }) => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (user && challengeId) {
      fetchUserSubmissions();
    }
  }, [user, challengeId]);

  const fetchUserSubmissions = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/admin/challenges/${challengeId}/users/${user.userId}/submissions`);
      setSubmissions(response.data);
    } catch (error) {
      setError('Failed to fetch user submissions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleMarkCorrect = async (submissionId, taskId, isCorrect) => {
    try {
      await api.put(`/admin/submissions/${submissionId}/tasks/${taskId}/mark-correct`, { isCorrect });
      setSuccess('Task marked successfully');
      fetchUserSubmissions();
    } catch (error) {
      setError('Failed to mark submission. Please try again.');
    }
  };

  const handleVerificationStatus = async (submissionId, needsVerification) => {
    try {
      await api.put(`/admin/submissions/${submissionId}/verification-status`, { needsVerification });
      setSuccess('Verification status updated successfully');
      fetchUserSubmissions();
    } catch (error) {
      setError('Failed to update verification status. Please try again.');
    }
  };

  const renderMedia = (answer) => {
    if (!answer) return null;
    
    const isImage = answer.match(/\.(jpeg|jpg|gif|png)$/i);
    const isVideo = answer.match(/\.(mp4|mov|avi|webm)$/i);
    
    if (isImage) {
      return <img src={answer} alt="Submission" onClick={() => window.open(answer, '_blank')} />;
    } else if (isVideo) {
      return (
        <video controls width="100%" height="auto">
          <source src={answer} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return <a href={answer} target="_blank" rel="noopener noreferrer">View Media</a>;
  };

  const renderTask = (submission, task, taskIndex) => (
    <Col md={6} key={task.taskId}>
      <TaskContainer>
        <Card.Body>
          <h5>Task {taskIndex + 1}</h5>
          <p><strong>Description:</strong> {task.description}</p>
          <p><strong>Type:</strong> {task.type}</p>
          <p><strong>Answer:</strong> {task.answer}</p>
          <MediaContainer>
            {renderMedia(task.answer)}
          </MediaContainer>
          <Form.Check
            type="checkbox"
            label="Mark as correct"
            checked={task.isCorrect}
            onChange={(e) => handleMarkCorrect(submission._id, task.taskId, e.target.checked)}
          />
        </Card.Body>
      </TaskContainer>
    </Col>
  );

  if (!user) return null;

  return (
    <StyledModal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Submissions for {user.username}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Spinner animation="border" />}
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        {!loading && !error && (
          <Accordion>
            {submissions.map((submission, submissionIndex) => (
              <Accordion.Item key={submission._id} eventKey={submissionIndex.toString()}>
                <Accordion.Header>
                  Attempt {submission.attemptNumber} - {submission.isCompleted ? 'Completed' : 'In Progress'}
                </Accordion.Header>
                <Accordion.Body>
                  <Card>
                    <Card.Header>
                      <Row>
                        <Col>Attempt {submission.attemptNumber}</Col>
                        <Col className="text-end">
                          <Form.Check
                            type="switch"
                            id={`verification-switch-${submission._id}`}
                            label="Needs Verification"
                            checked={submission.needsVerification}
                            onChange={(e) => handleVerificationStatus(submission._id, e.target.checked)}
                          />
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        {submission.taskSubmissions.map((task, taskIndex) => 
                          renderTask(submission, task, taskIndex)
                        )}
                      </Row>
                      <p><strong>Completed:</strong> {submission.isCompleted ? 'Yes' : 'No'}</p>
                      <p><strong>Correct:</strong> {submission.isCorrect ? 'Yes' : 'No'}</p>
                      <p><strong>Completed At:</strong> {submission.completedAt ? new Date(submission.completedAt).toLocaleString() : 'N/A'}</p>
                    </Card.Body>
                  </Card>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default UserSubmissionsModal;